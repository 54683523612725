import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { UtilService } from '../../services/util.service';
import { ImagemService } from '../../services/imagem.service';
import { ModalController, LoadingController, AlertController, IonThumbnail } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  uploadForm: FormGroup;
  files: File[] = [];
  Imagem: string = '';
  Idtabela: number = 0;
  Tabela: string = "";
  Coluna: string = "";
  ImagemServidor: string = '';
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private imagemService: ImagemService,
    private utilService: UtilService,
    private modalController: ModalController,
    private activatedRoute: ActivatedRoute,
    private loadingController: LoadingController,
    

  ) {

    this.Imagem = this.activatedRoute.snapshot.paramMap.get("Imagem");
    this.Tabela = this.activatedRoute.snapshot.paramMap.get('Tabela');
    this.Coluna = this.activatedRoute.snapshot.paramMap.get('Coluna');
    this.Idtabela = parseInt(this.activatedRoute.snapshot.paramMap.get('Idtabela'));

    this.uploadForm = this.fb.group({
      files: [null, Validators.required]
    });


  }

  ngOnInit() {

    if (this.Imagem != '' && this.Imagem != null && this.Imagem != undefined) {
      this.ImagemServidor = this.utilService.obterUrlDaImagemDaApi() + this.Imagem
    }
  }

  ionViewDidEnter() {


  }

  closeModal() {
    this.modalController.dismiss();
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.files = Array.from(event.target.files);
      this.uploadForm.patchValue({
        files: this.files
      });
    }
  }

  onSubmit() {
    const formData = new FormData();
    let randonNumero = Math.floor(Math.random() * 1000000);
    let nome: string = randonNumero.toString() + this.Tabela + this.Idtabela.toString()
    this.files.forEach(file => formData.append('files', file, nome + '.' + file.name.split('.')[1]));

    if (this.files.length > 1) {
      this.utilService.showAlertCallback("Só é possível enviar um arquivo de cada vez.", null);
      return;
    }


    if (this.temExtensaoValida(this.files) == false) {
      this.utilService.showAlertCallback("São só aceitos arquivos do tipo png, jpg, jpeg, gif e jfif", null);
      return;
    }

    this.enviarFotoTabela(formData, nome + '.' + this.files[0].name.split('.')[1])


  }


  temExtensaoValida(files: any[]) {
    let temExtensaoValida = true;
    files.forEach((file) => {
      let extensao: string = file.name.split('.')[1];

      if (extensao.toLowerCase() != 'jpeg'
        && extensao.toLowerCase() != 'gif' && extensao.toLowerCase() != 'png'
        && extensao.toLowerCase() != 'jpeg' && extensao.toLowerCase() != 'jpg'
        && extensao.toLowerCase() != 'jfif') {
        temExtensaoValida = false;
      }

    });
    return temExtensaoValida;
  }


  async enviarFotoTabela(formData: any, nome: string) {
    let loading = await this.loadingController.create({ message: "Verificando..." });
    loading.present();
    this.imagemService.enviarImagemTabela(formData).then((r) => {
      this.atualizarImagemGeral(nome);
      loading.dismiss();
    }).catch((r) => {
      this.utilService.showAlertCallback('Imagem não pode ser enviada');
      loading.dismiss();

    }).finally(() => {
      loading.dismiss();
    })
  }

  async atualizarImagemGeral(nome: string) {
    let loading = await this.loadingController.create({ message: "Atualizando..." });
    loading.present();

    let jImagem = {
      Tabela: this.Tabela,
      Idtabela: this.Idtabela,
      Nome: nome,
      Descricao: 'descricao',
      Texto: 'texto',
      Usuario: 1,
      Coluna: this.Coluna

    };

    //this.ImagemServidor="/assets/remove_uti.png";

    setTimeout(() => {
      this.imagemService.atualizarImagemGeral(jImagem).then((response: any) => {
        loading.dismiss();

        this.utilService.showAlertCallback('Imagem enviada com SUCESSO!', () => {
          this.ImagemServidor = this.utilService.obterUrlDaImagemDaApi() + nome;
          this.Imagem = nome; // nome da imagem
        });
      }).catch(() => {
        loading.dismiss();
        this.utilService.showAlertCallback('Ocorreu algum problema. Imagem NÃO enviada', () => {
          this.ImagemServidor = '';

        }).finally(() => {
          loading.dismiss();
        })
      })

    }, 500)




  }



 
  

}
