import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavController, ToastController } from '@ionic/angular';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { SistemaModule } from '../sistema/sistema.module';
@Injectable({
  providedIn: 'root'
})
export class UtilService {
  lRet: any;
  lCnpj: any;
  timer: any;
  aTipo: any = [];
  aPessoa: any = [];
  docDefinition: any;
  pdfObj = null;
  public dadosRemove = new SistemaModule();
  isOnline:boolean=false;
  aUsuario: Array<
    {
      Id: number,
      NomeUsuario: string,
      EmailUsuario: string,
      DiaUsuario: number,
      MesUsuario: number,
      AnoUsuario: number,
      Logado: number,
      Administrador: number,
      Coordenador: number,
      Conselho: number,
      Imagem: string,
      UsuarioPesquisa: number,
      PacientePesquisa: number,
      EmpresaPesquisa: number,
      ProdutoPesquisa: number,
      ContratoPesquisa: number,
      VeiculoPesquisa: number,
      RemocaoPesquisa: number
    }> = [];
  aRemocao: Array<
    {
      Id: number,
      Veiculo: number;
      Condutor: number;
      Estado: number;
      Municipio: number;
      Enfermeiro: number,
      Medico: number,
      Km: number
    }> = [];
  constructor(
    private navController: NavController,
    private alertController: AlertController,
    private http: HttpClient,
    private loadingController: LoadingController,
    private toastController: ToastController,
  ) { }


  obterUrlDaApi() {
   // return "http://localhost:52310/api/";
    return "https://api.removeuti.com.br/api/"

  }

  obterUrlDaImagemDaApi() { // DOWNLOAD 
    //return "http://localhost:52310/Uploads/files/" //rodando local direto da api
    return "https://api.removeuti.com.br/Uploads/files/"; //rodando apontando para producao
  }

  async Movimentar(pagina: string) {
    this.navController.navigateForward(pagina);
  }


  checkInternetConnection() {
    this.isOnline = window.navigator.onLine;
    if (this.isOnline) {
     // console.log('Conectado à Internet');
    } else {
      //console.log('Sem conexão com a Internet');
    }

    // Monitorar mudanças no status da conexão
    window.addEventListener('online', () => {
      this.isOnline = true;
     // console.log('Conexão de internet reestabelecida');
    });

    window.addEventListener('offline', () => {
      this.isOnline = false;
     // console.log('Conexão de internet perdida');
    });

    return this.isOnline
  }


  async showAlertCallback(message: string, callback = null) {
    const alert = await this.alertController.create({
      message: message,
      header: 'ATENÇÃO',
      mode: 'ios',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            if (callback != null) {
              callback();
            }
          }
        }
      ]
    });
    await alert.present()

  }

  async showToasttCallback(message: string, callback = null) {
    const alert = await this.toastController.create({
      message: message,
      header: 'VERIFIQUE',
      mode: 'ios',
      duration: 10000,
      color: 'light',
      position: "bottom",
      buttons: [
        {
          side: 'end',
          icon: 'alert',
          text: 'OK',
          handler: () => {
            if (callback != null) {
              callback();
            }
          }
        }
      ]
    });
    await alert.present()

  }




  obterEndereco(cep: string) {
    let url = 'https://viacep.com.br/ws/' + cep + '/json/'

    return this.http.get(url).toPromise();
  }

  obterMunicipio(estado: string) {
    let url = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + estado + '/municipios'
    return this.http.get(url).toPromise();
  }

  valiidarCampoGenerico(campo: string, valor: string, Callback: any = null) {

    let aCampo: any = [];
    let lRet: any = true;

    aCampo.push({ campo: 'Remocao', mensagem: 'Informe a <strong>REMOÇÃO</strong>', valor: '' })
    aCampo.push({ campo: 'Nome', mensagem: 'Informe o <strong>NOME</strong>', valor: '' })
    aCampo.push({ campo: 'Data', mensagem: 'Informe o <strong>DATA</strong>', valor: '' })
    aCampo.push({ campo: 'Veiculo', mensagem: 'Informe o <strong>VEÍCULO</strong>', valor: '' })
    aCampo.push({ campo: 'Km', mensagem: 'Informe o <strong>KM DO VEÍCULO</strong>', valor: '' })
    aCampo.push({ campo: 'Condutor', mensagem: 'Informe o <strong>CONDUTOR</strong>', valor: '' })
    aCampo.push({ campo: 'Statusatual', mensagem: 'Informe o <strong>STATUS ATUAL DA REMOÇÃO</strong>', valor: '' })
    aCampo.push({ campo: 'Estado', mensagem: 'Informe o <strong>ESTADO</strong>', valor: '' })
    aCampo.push({ campo: 'Municipio', mensagem: 'Informe o <strong>MUNICÍPIO</strong>', valor: '' })
    aCampo.push({ campo: 'Localatual', mensagem: 'Informe o <strong>LOCALIZAÇÃO ATUAL</strong>', valor: '' })
    aCampo.push({ campo: 'Enfermeiro', mensagem: 'Informe o <strong>ENFERMEIRO</strong>', valor: '' })
    aCampo.push({ campo: 'Medico', mensagem: 'Informe o <strong>MÉDICO</strong>', valor: '' })
    aCampo.push({ campo: 'Municipio', mensagem: 'Informe o <strong>MUNICÍPIO</strong>', valor: '' })
    aCampo.push({ campo: 'Data', mensagem: 'Informe o <strong>DATA</strong>', valor: '' })

    for (let i = 0; i < aCampo.length; i++) {
      if (aCampo[i].campo == campo) {
        if (aCampo[i].valor == valor) {
          lRet = false;
          this.showAlertCallback(aCampo[i].mensagem, Callback);
          return lRet
          break;
        }
      }
    }

  }


  validarCampo(campo: string, valor: string, toastCallback: any = null) {
    let lRet: any = true;
    let aCarFalse: any = []
    aCarFalse.push("'");
    aCarFalse.push("#");
    aCarFalse.push("&");


    if (campo.indexOf('Faixa') > -1) { // Comorbidade
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa informar a faixa de idade', toastCallback);
        return lRet
      }
    }
    if (campo.indexOf('Valor') > -1) { // Comorbidade
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o valor', toastCallback);
        return lRet
      }
    }

    if (campo.indexOf('Desconto') > -1) { // Comorbidade
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o desconto', toastCallback);
        return lRet
      }
    }

    if (campo == 'NomeVeiculo') { // Comorbidade
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o Nome ou Modelo do ve', toastCallback);
        return lRet
      }
    }

    if (campo == 'PlacaVeiculo') { // Comorbidade
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o Placa do ve', toastCallback);
        return lRet
      }
    }

    if (campo == 'AnoVeiculo') { // Comorbidade
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o Ano ou Modelo do ve', toastCallback);
        return lRet
      }
    }

    if (campo == 'RenavanVeiculo') { // Comorbidade
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o Renavan do veículo', toastCallback);
        return lRet
      }
    }

    if (campo == "Diapagamento") { // Comorbidade
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o DIA do pagamento', toastCallback);
        return lRet
      }

    }


    if (campo == "Dia") { // Comorbidade
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o DIA do seu nascimento', toastCallback);
        return lRet
      }

      if (parseInt(valor) < 1 || parseInt(valor) > 31) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o DIA do seu nascimento corretamente', toastCallback);
        return lRet
      }

    }

    if (campo == "Mes") { // Comorbidade
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o MÊS do seu nascimento', toastCallback);
        return lRet
      }

      if (parseInt(valor) < 1 || parseInt(valor) > 12) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o MÊS do seu nascimento corretamente', toastCallback);
        return lRet
      }

    }

    if (campo == "Ano") { // Comorbidade
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o ANO do seu nascimento', toastCallback);
        return lRet
      }

      let hoje = new Date();
      let ano: any = hoje.getFullYear();

      if (parseInt(valor) < 1900 || parseInt(valor) > ano) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o ANO do seu nascimento corretamente', toastCallback);
        return lRet
      }

    }
    if (campo == "Comorbidade") { // Comorbidade
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar as comorbidades', toastCallback);
        return lRet
      }
    }

    if (campo == "Referencia") { // Comorbidade
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar a referência', toastCallback);
        return lRet
      }
    }

    if (campo == "Convenio") { // Conveino de email
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o convênio', toastCallback);
        return lRet
      }
    }


    if (campo == "Documento") { // documento de email
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o documento do responsavel', toastCallback);
        return lRet
      }
    }

    if (campo == "Cartaosus") { // Cartao SUS
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o cartão sus do paciente', toastCallback);
        return lRet
      }
    }

    if (campo == "Observacao") { // Observacao
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar as obervações do paciente', toastCallback);
        return lRet
      }
    }

    if (campo == "Mensagem") { // mensagem de email
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar sua mensagem', toastCallback);
        return lRet
      }
    }

    if (campo == "Validacao") { // reinvio de senha
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o seu código de validação', toastCallback);
        return lRet
      }
    }
    if (campo == "Codigovalidacao") { // após realizar o cadastro
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o seu código de validação', toastCallback);
        return lRet
      }

      let dNow = new Date();
      let dia = dNow.getDate()
      let mes = dNow.getMonth() + 1

      let Validacao = dia.toString() + mes.toString();
      valor = valor.toString();
      if (valor.indexOf(Validacao) == - 1) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o seu código de validação corretamente', toastCallback);
        return lRet

      }

    }

    if (campo == "Curriculo") {
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o seu currículo', toastCallback);
        return lRet
      }
    }

    if (campo == "Complemento") {
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o complemento de seu logradouro', toastCallback);
        return lRet
      }
    }

    if (campo == "Numero") { // mensagem de email
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o número', toastCallback);
        return lRet
      }
    }


    if (campo == "Peso") {
      if (valor == '' || valor == undefined || valor == null) {
        lRet = false;
        this.showAlertCallback('<strong>PESO</strong> em Kg não informado ou inválido', toastCallback);
        return lRet
      }
    }

    if (campo == "Idade") {
      if (valor == '' || valor == undefined || valor == null) {
        lRet = false;
        this.showAlertCallback('Informe a <strong>IDADE</strong> em ANOS', toastCallback);
        return lRet
      }
    }


    if (campo == "Altura") {
      if (valor.indexOf('.') > -1 || valor.indexOf(',') > -1) {
        lRet = false;
        this.showAlertCallback('Altura em cm do paciente. Não há necessidade de ponto ou virgula', toastCallback);
        return lRet
      }

      if (parseInt(valor) == 0 || valor == '' || valor == undefined || valor == null || parseInt(valor) < 90 || parseInt(valor) > 250) {
        lRet = false;
        this.showAlertCallback('Altura em cm não informado ou inválida', toastCallback);
        return lRet
      }


    }



    if (campo == "Usuario") {
      if (parseInt(valor) == 0 || valor == '' || valor == undefined || valor == null) {
        lRet = false;
        this.showAlertCallback('Usuário não selecionado. Você precisa validar seu cadastro', toastCallback);
        return lRet
      }
    }

    if (campo == "Paciente") {
      if (parseInt(valor) == 0 || valor == '' || valor == undefined || valor == null) {
        lRet = false;
        this.showAlertCallback('Paciente não selecionado. Você precisa primeiro cadastrar o paciente', toastCallback);
        return lRet
      }
    }


    if (campo == "Nome") {
      if (valor == "" || valor.indexOf(" ") == -1) {
        lRet = false;
        this.showAlertCallback('Nome obrigatório com sobrenome', toastCallback);
        return lRet
      }
    }

    if (campo == "Descricao") {
      if (valor == "" || valor.indexOf(" ") == -1) {
        lRet = false;
        this.showAlertCallback('Descrição obrigatória', toastCallback);
        return lRet
      }
    }

    if (campo == "Responsavel") {
      if (valor == "" || valor.indexOf(" ") == -1) {
        lRet = false;
        this.showAlertCallback('Responsável obrigatório com sobrenome', toastCallback);
        return lRet
      }
    }

    if (campo == "Tiporemocao") {
      if (valor == "" || valor == null || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Informe o tipo de remoção', toastCallback);
        return lRet
      }
    }

    if (campo == "Covid") {
      if (valor == '' || valor == null || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Informe se o paciente tem <strong>COVID 19</strong> ou não', toastCallback);
        return lRet
      }
    }

    if (campo == "Numeroconselho") {
      if (valor == '') {
        lRet = false;
        this.showAlertCallback('Número do conselho inválido. Obrigatório informar o número do seu conselho', toastCallback);
        return lRet
      }
    }

    if (campo == "Documento") {
      if (valor == '') {
        lRet = false;
        this.showAlertCallback('Documento inválido.', toastCallback);
        return lRet
      }
    }

    if (campo == "Cnpj") {
      let cnpj = valor;
      this.lCnpj = true;
      if (cnpj == '' || cnpj == undefined || cnpj == null || cnpj.length == 0) {
        this.showAlertCallback('CNPJ em branco', toastCallback);
        lRet = false;
        return lRet
      }

      if (cnpj.length != 14) {
        this.showAlertCallback('CNPJ deve ter 14 dígitos', toastCallback);
        lRet = false;
        return lRet
      }

      // numeros repetidos
      //137797070001 => 12
      let n = '';

      for (let i = 0; i < 10; i++) {
        n = '';

        if (this.lCnpj == false) {
          break;
        }
        for (let z = 0; z < 15; z++) {
          n = n + i.toString();
          if (cnpj == n) {
            this.showAlertCallback('CNPJ inválido. Números iguais', toastCallback);
            lRet = false;
            return lRet

          }
        }

      }



      let soma: any = 0


      soma = 0  // verificador do primeiro digito do
      let fator: any = [];
      fator = '5,4,3,2,9,8,7,6,5,4,3,2';
      fator = fator.split(',');
      for (let t = 0; t < 12; t++) {
        soma = soma + parseInt(cnpj.substring(t, t + 1)) * fator[t];
      }

      soma = soma / 11;
      soma = soma.toString();
      let sobra1: any = '';
      if (soma.indexOf('.') > 0) {
        sobra1 = soma.split(".");
        sobra1 = sobra1[1].toString();
        sobra1 = parseFloat(sobra1.substring(0, 1) + '.' + sobra1.substring(1));
        sobra1 = sobra1.toFixed(1);
        let x: number = 9
        for (let p = 1; p < 10; p++) {
          if (sobra1 > x) {
            sobra1 = x + 1
            break;
          }
          x--
        }
      } else {
        sobra1 = 0;
      }
      if (sobra1 >= 2) {
        sobra1 = 11 - sobra1
      } else {
        sobra1 = 0
      }

      let dv1: string = sobra1.toString();
      let sobra2: any = '';

      soma = 0  // verificador do segundo digito do
      fator = '6,5,4,3,2,9,8,7,6,5,4,3,2';
      fator = fator.split(',');    // acrescento o primeiro digito verificador aos 9 primeiros número
      for (let t = 0; t < 13; t++) {
        soma = soma + parseInt(cnpj.substring(t, t + 1)) * fator[t];
      }
      ;
      soma = soma / 11;
      soma = soma.toString();
      if (soma.indexOf('.') > 0) {
        sobra2 = soma.split(".");
        sobra2 = sobra2[1].toString();
        sobra2 = parseFloat(sobra2.substring(0, 1) + '.' + sobra2.substring(1));
        sobra2 = sobra2.toFixed(1);
        let x: number = 9
        for (let p = 1; p < 10; p++) {
          if (sobra2 > x) {
            sobra2 = x + 1
            break;
          }
          x--
        }

      } else {
        sobra2 = 0
      }

      if (sobra2 >= 2) {
        sobra2 = 11 - sobra2
      } else {
        sobra2 = 0
      }

      let dv2: string = sobra2.toString();
      let dv: string = dv1 + dv2;
      let dvcnpj: string = cnpj.substring(12, 14)
      if (dv != dvcnpj) {
        this.showAlertCallback('CNPJ inválido. Dígito não confere', toastCallback);
        lRet = false;
        return lRet
      }
      return lRet;
      // fim do valida CNPJ
    }

    if (campo == "Cpf") {

      if (valor == '' || valor == null || valor == undefined) {
        lRet = false;
        this.showAlertCallback('CPF em branco', toastCallback);
        return lRet
      }

      if (valor.length < 11 || valor.length > 12) {
        lRet = false;
        this.showAlertCallback('CPF inválido ', toastCallback);
        return lRet
      }

      let POSICAO, I, SOMA, DV, DV_INFORMADO;
      let DIGITO = new Array(10);
      let cpf = valor;
      DV_INFORMADO = cpf.toString().substr(9, 2); // Retira os dois últimos dígitos do número informado

      // Desemembra o número do CPF na array DIGITO
      for (I = 0; I <= 8; I++) {
        DIGITO[I] = cpf.toString().substr(I, 1);
      }

      // Calcula o valor do 10º dígito da verificação
      POSICAO = 10;
      SOMA = 0;
      for (I = 0; I <= 8; I++) {
        SOMA = SOMA + DIGITO[I] * POSICAO;
        POSICAO = POSICAO - 1;
      }
      DIGITO[9] = SOMA % 11;
      if (DIGITO[9] < 2) {
        DIGITO[9] = 0;
      }
      else {
        DIGITO[9] = 11 - DIGITO[9];
      }

      // Calcula o valor do 11º dígito da verificação
      POSICAO = 11;
      SOMA = 0;
      for (I = 0; I <= 9; I++) {
        SOMA = SOMA + DIGITO[I] * POSICAO;
        POSICAO = POSICAO - 1;
      }
      DIGITO[10] = SOMA % 11;
      if (DIGITO[10] < 2) {
        DIGITO[10] = 0;
      }
      else {
        DIGITO[10] = 11 - DIGITO[10];
      }

      // Verifica se os valores dos dígitos verificadores conferem
      DV = DIGITO[9] * 10 + DIGITO[10];
      if (DV != DV_INFORMADO) {
        this.showAlertCallback('CPF inválido ', toastCallback);
        lRet = false;
      }

    }



    if (campo == "Data") {
      let dn: any = valor;
      if (dn == true || dn == '' || dn == '0' || dn == null || dn == undefined || dn == '1900/01/01') {
        lRet = false;
        this.showAlertCallback('Data obrigatório', toastCallback);
        return lRet
      }

    }

    if (campo == "Datanascimento") {
      let dn: any = valor;
      if (dn == true || dn == '' || dn == '0' || dn == null || dn == undefined || dn == '1900/01/01') {
        lRet = false;
        this.showAlertCallback('Data de nascimento obrigatório', toastCallback);
        return lRet
      }

    }


    if (campo == "Sexo") {
      if (valor != '1' && valor != '2' && valor != '3') {
        lRet = false;
        this.showAlertCallback('SEXO obrigatório', toastCallback);
        return lRet
      }


    }


    if (campo == "Celular") {
      if (valor == '' || valor == null || valor == undefined) {
        lRet = false;
        this.showAlertCallback('CELULAR em branco. Obrigatório com 11  digitos. Use o DDD  (ex:11,21,71,73) e número sem traços e espaços.', toastCallback);
        return lRet
      }
      let cel: string = valor.toString();
      let lengthAtual = cel.length;
      if (lengthAtual != 11) {
        lRet = false;
        this.showAlertCallback('CELULAR inválido. Obrigatório com 11 digitos. Use o DDD  (ex:11,21,71,73) e número sem traços e espaços.', toastCallback);
        return lRet
      }

      let snumero: string = '';
      for (let i = 0; i < valor.length; i++) {
        let x = i;
        snumero = valor.substring(x, x + 1);
        if (isNaN(parseInt(snumero) / 1)) {
          lRet = false;
          this.showAlertCallback('CELULAR com caracteres inválido. Obrigatório com 11 digitos. Use o DDD  (ex:11,21,71,73) e número sem traços e espaços.', toastCallback);
          break;
          return lRet
        }

      }
    }


    if (campo == "Senha") {
      if (valor == '' || valor == undefined || valor == null) {
        lRet = false;
        this.showAlertCallback('SENHA obrigatório. Obrigatório de 6 a 12 digitos', toastCallback);
        return lRet
      } else {
        let lengthAtual = valor.length;
        if (lengthAtual < 6 || lengthAtual > 12) {
          lRet = false;
          this.showAlertCallback('SENHA inválida. Obrigatório de 6 a 12 digitos', toastCallback);
          return lRet
        }
      }

      if (valor.indexOf('#') > -1) {
        lRet = false;
        this.showAlertCallback('SENHA inválida. Não use #', toastCallback);
        return lRet
      }
      if (valor.indexOf('!') > -1) {
        lRet = false;
        this.showAlertCallback('SENHA inválida. Não use !', toastCallback);
        return lRet
      }
      if (valor.indexOf('$') > -1) {
        lRet = false;
        this.showAlertCallback('SENHA inválida. Não use $', toastCallback);
        return lRet

      }
      if (valor.indexOf('%') > -1) {
        lRet = false;
        this.showAlertCallback('SENHA inválida. Não use %', toastCallback);
        return lRet
      }
      if (valor.indexOf('¨¨') > -1) {
        lRet = false;
        this.showAlertCallback('SENHA inválida. Não use ¨¨', toastCallback);
        return lRet
      }
      if (valor.indexOf('&¨') > -1) {
        lRet = false;
        this.showAlertCallback('SENHA inválida. Não use &', toastCallback);
        return lRet
      }
      if (valor.indexOf('*¨') > -1) {
        lRet = false;
        this.showAlertCallback('SENHA inválida. Não use *', toastCallback);
        return lRet
      }

    }

    if (campo == "Cep") {
      if (valor == '') {
        lRet = false;
        this.showAlertCallback('CEP obrigatório. Obrigatório 8 digitos', toastCallback);
        return lRet
      } else {
        let cep: string = valor.toString();
        let lengthAtual = cep.length;
        if (lengthAtual != 8) {
          lRet = false;
          this.showAlertCallback('CEP inválido. Obrigatório 8 digitos', toastCallback);
          return lRet
        }
      }
    }

    if (campo == "Logradouro") {
      if (valor == '') {
        lRet = false;
        this.showAlertCallback('Logradouro inválido. Obrigatório mínimo de 3 dígitos', toastCallback);
        return lRet
      } else {
        let lengthAtual = valor.length;
        if (lengthAtual < 3) {
          lRet = false;
          this.showAlertCallback('Logradouro inválido. Obrigatório mínimo de 3 dígitos', toastCallback);
          return lRet
        }
      }

    }
    if (campo == "Bairro") {
      if (valor == '') {
        lRet = false;
        this.showAlertCallback('Bairro inválido. Obrigatório mínimo de 3 dígitos', toastCallback);
        return lRet
      } else {
        let lengthAtual = valor.length;
        if (lengthAtual < 3) {
          lRet = false;
          this.showAlertCallback('Bairro inválido. Obrigatório mínimo de 3 dígitos', toastCallback);
          return lRet
        }
      }

    }
    if (campo == "Estado") {
      if (parseInt(valor) == 0 || valor == '') {
        lRet = false;
        this.showAlertCallback('Estado inválido. Escolha o estado na lista', toastCallback);
        return lRet
      }
    }

    if (campo == "Conselho") {
      if (parseInt(valor) == 0 || valor == '') {
        lRet = false;
        this.showAlertCallback('Conselho inválido. Escolha o conselho na lista', toastCallback);
        return lRet
      }
    }

    if (campo == "Localidade") {
      if (valor == '') {
        lRet = false;
        this.showAlertCallback('Cidade inválida. Obrigatório mínimo de 3 dígitos', toastCallback);
        return lRet
      } else {
        let lengthAtual = valor.length;
        if (lengthAtual < 3) {
          lRet = false;
          this.showAlertCallback('Cidade inválida. Obrigatório mínimo de 3 dígitos', toastCallback);
          return lRet
        }
      }

    }
    return lRet
  }

  async selecionarConvenio() {
    let aConvenio: any = [];
    aConvenio.push({ Id: 1, Nome: 'Cartão Remove você' });
    aConvenio.push({ Id: 2, Nome: 'Particular' });
    aConvenio.push({ Id: 3, Nome: 'SESAB' });
    aConvenio.push({ Id: 4, Nome: 'Unimed' });
    aConvenio.push({ Id: 5, Nome: 'Plaserv' });
    aConvenio.push({ Id: 6, Nome: 'Bradesco' });
    aConvenio.push({ Id: 7, Nome: 'Sul América' });
    aConvenio.push({ Id: 8, Nome: 'Outro' });
    aConvenio.push({ Id: 8, Nome: 'Cortesia' });
    aConvenio.push({ Id: 9, Nome: 'Prefeitura' });

    return aConvenio;
  }

  async selecionarStatusRemocao() {
    let aStatus: any = [];
    aStatus.push({ Id: 1, Nome: 'Saída da Base' });
    aStatus.push({ Id: 2, Nome: 'Chegada a Origem' });
    aStatus.push({ Id: 3, Nome: 'Saída da Origem' });
    aStatus.push({ Id: 4, Nome: 'Chegada ao Destino' });
    aStatus.push({ Id: 5, Nome: 'Saída do Destino' });
    aStatus.push({ Id: 6, Nome: 'Retorno a Origem' });
    aStatus.push({ Id: 7, Nome: 'Retorno a Base' });
    aStatus.push({ Id: 8, Nome: 'Parada Base -> Origem' });
    aStatus.push({ Id: 9, Nome: 'Parada Origem -> Destino' });
    aStatus.push({ Id: 10, Nome: 'Parada Destino -> Base' });
    aStatus.push({ Id: 11, Nome: 'Outra Parada' });


    return aStatus;
  }

  async selecionarEstado() {
    let aEstado: any = [];

    aEstado.push({ Id: 1, Nome: 'Acre', Sigla: 'AC', Ibge: 12 });
    aEstado.push({ Id: 2, Nome: 'Alagoas', Sigla: 'AL', Ibge: 27 });
    aEstado.push({ Id: 3, Nome: 'Amapá', Sigla: 'AP', Ibge: 16 });
    aEstado.push({ Id: 4, Nome: 'Amazonas', Sigla: 'AM', Ibge: 13 });
    aEstado.push({ Id: 5, Nome: 'Bahia', Sigla: 'BA', Ibge: 29 });
    aEstado.push({ Id: 6, Nome: 'Ceará', Sigla: 'CE', Ibge: 23 });
    aEstado.push({ Id: 7, Nome: 'Distrito Federal', Sigla: 'DF', Ibge: 53 });
    aEstado.push({ Id: 8, Nome: 'Espírito Santo', Sigla: 'ES', Ibge: 32 });
    aEstado.push({ Id: 9, Nome: 'Goiás', Sigla: 'GO', Ibge: 52 });;
    aEstado.push({ Id: 10, Nome: 'Maranhão', Sigla: 'MA:', Ibge: 21 });
    aEstado.push({ Id: 11, Nome: 'Mato Grosso', Sigla: '	MT', Ibge: 51 });
    aEstado.push({ Id: 12, Nome: 'Mato Grosso do Sul', Sigla: 'MS', Ibge: 50 });
    aEstado.push({ Id: 13, Nome: 'Minas Gerais', Sigla: 'MG', Ibge: 31 });
    aEstado.push({ Id: 14, Nome: 'Pará', Sigla: 'PA', Ibge: 15 });
    aEstado.push({ Id: 15, Nome: 'Paraíba', Sigla: 'PB', Ibge: 25 });
    aEstado.push({ Id: 16, Nome: 'Paraná', Sigla: 'PR', Ibge: 41 });
    aEstado.push({ Id: 17, Nome: 'Pernambuco', Sigla: '	PE', Ibge: 26 });
    aEstado.push({ Id: 18, Nome: 'Piauí', Sigla: 'PI', Ibge: 22 });
    aEstado.push({ Id: 19, Nome: 'Rio de Janeiro', Sigla: 'RJ', Ibge: 33 });
    aEstado.push({ Id: 20, Nome: 'Rio Grande do Norte', Sigla: 'RN', Ibge: 24 });
    aEstado.push({ Id: 21, Nome: 'Rio Grande do Sul', Sigla: 'RS', Ibge: 43 });
    aEstado.push({ Id: 22, Nome: 'Rondônia', Sigla: 'RO', Ibge: 11 });
    aEstado.push({ Id: 23, Nome: 'Roraima', Sigla: 'RR', Ibge: 14 });
    aEstado.push({ Id: 24, Nome: 'Santa Catarina', Sigla: 'SC', Ibge: 42 });
    aEstado.push({ Id: 25, Nome: 'São Paulo', Sigla: 'SP', Ibge: 35 });
    aEstado.push({ Id: 26, Nome: 'Sergipe', Sigla: 'SE', Ibge: 28 });
    aEstado.push({ Id: 27, Nome: 'Tocantins', Sigla: 'TO', Ibge: 17 });

    return aEstado;

  }

  async selecionarTipoRemocao() {
    let aTipoRemocao: any = [];
    aTipoRemocao.push({ Id: 1, Nome: 'Adulto' });
    aTipoRemocao.push({ Id: 2, Nome: 'Pediátrico' })
    aTipoRemocao.push({ Id: 3, Nome: 'Neo-Natal' })

    return aTipoRemocao;
  }

  async selecionarSexo() {
    let aSexo: any = [];
    aSexo.push({ Id: 1, Nome: 'Masculino' });
    aSexo.push({ Id: 2, Nome: 'Feminino' })
    aSexo.push({ Id: 3, Nome: 'Prefiro não informar' })

    return aSexo;
  }

  async selecionarConselho() {
    let aConselho: any = [];
    aConselho.push({ Id: 1, Nome: 'Conselho Regional de Assistência Social', Sigla: 'CRAS', Profissional: 'Assistentes Sociais', Mostrar: 0 });
    aConselho.push({ Id: 2, Nome: 'Conselho Federal de Enfermagem', Sigla: 'COREN', Profissional: 'Enfermeiros', Mostrar: 1 });
    aConselho.push({ Id: 3, Nome: 'Conselho Regional de Farmácia', Sigla: 'CRF', Profissional: '	Farmacêuticos', Mostrar: 1 });
    aConselho.push({ Id: 4, Nome: 'Conselho Regional de Fonoaudiologia', Sigla: 'CRFA', Profissional: 'Fonodiaulogos', Mostrar: 1 });
    aConselho.push({ Id: 5, Nome: 'Conselho Regional de Fisioterapia e Terapia Ocupacional', Sigla: 'CREFITO', Profissional: 'Fisioterapeutas', Mostrar: 1 });
    aConselho.push({ Id: 6, Nome: 'Conselho Regional de Medicina', Sigla: 'CRM', Profissional: 'Médicos', Mostrar: 1 });
    aConselho.push({ Id: 7, Nome: 'Conselho Regional de Medicina Veterinária', Sigla: 'CRV', Profissional: '	Veterinários', Mostrar: 1 });
    aConselho.push({ Id: 8, Nome: 'Conselho Regional de Nutrição', Sigla: 'CRN', Profissional: 'Nutricionistas', Mostrar: 1 });
    aConselho.push({ Id: 9, Nome: 'Conselho Regional de Odontologia', Sigla: 'CRO', Profissional: 'Dentistas ', Mostrar: 1 });
    aConselho.push({ Id: 10, Nome: 'Conselho Regional de Psicologia', Sigla: 'CRP', Profissional: 'Psicólogos', Mostrar: 1 });
    aConselho.push({ Id: 11, Nome: 'Outros Conselhos', Sigla: 'OUTROS', Profissional: 'Auxiliares', Mostrar: 1 });
    aConselho.push({ Id: 12, Nome: 'Estudante de medicina', Sigla: 'EMED', Profissional: 'Estudantes', Mostrar: 1 });
    aConselho.push({ Id: 13, Nome: 'Estudante de enfermagem', Sigla: 'EENF', Profissional: 'Estudante', Mostrar: 1 });
    aConselho.push({ Id: 14, Nome: 'Estudante de odontologia', Sigla: '	EDON', Profissional: 'Estudante', Mostrar: 1 });
    aConselho.push({ Id: 15, Nome: 'Estudante de fisioterapia', Sigla: 'EFIS', Profissional: 'Estudante', Mostrar: 1 });
    aConselho.push({ Id: 16, Nome: 'Estudante de psicologia', Sigla: 'PIS', Profissional: 'Estudante', Mostrar: 1 });
    aConselho.push({ Id: 17, Nome: 'Estudante de nutrição', Sigla: 'ENUT', Profissional: 'Estudante', Mostrar: 1 });
    aConselho.push({ Id: 18, Nome: 'Condutor Socorrista', Sigla: 'CSOC', Profissional: 'Condutor', Mostrar: 1 });
    aConselho.push({ Id: 19, Nome: 'Ordem dos Advogados do Brasil', Sigla: 'OAB', Profissional: 'Advogado', Mostrar: 1 });
    aConselho.push({ Id: 20, Nome: 'Vendas', Sigla: 'VENDAS', Profissional: 'Vendendor', Mostrar: 1 });
    aConselho.push({ Id: 21, Nome: 'Administrativo', Sigla: 'ADM', Profissional: 'Administrador', Mostrar: 1 });

    aConselho.sort((x, y) => {
      let a = x.Nome.toUpperCase();
      let b = y.Nome.toUpperCase();
      return a == b ? 0 : a > b ? 1 : -1;
    })

    return aConselho
  }

  async sortNome(aVetor: any) {

    aVetor.sort((x, y) => {
      let a = x.Nome.toUpperCase();
      let b = y.Nome.toUpperCase();
      return (a == b ? 0 : a > b ? 1 : -1);
    });

    return aVetor;

  }

  async checkMail(mail: string, toastCallBack) {
    if (mail != '') {
      mail = mail.trim();
    }
    let er = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);
    let retorno = true;

    if (er.test(mail)) {
      if (mail.match(/^[a-zA-Z0-9]{1}([\._a-zA-Z0-9-]+)(\.[_a-zA-Z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+){1,3}$/)) {
        retorno = true;
      } else {
        retorno = false;
        this.showAlertCallback('Email está inválido', toastCallBack);

      }
    } else {
      retorno = false;
      this.showAlertCallback('Email está inválido', toastCallBack);
    }
    return retorno;

  }



  async checaData(data: any, formato: string = '') {
    // retorna a hora também
    if (data.toString().indexOf('T') > -1) {
      data = data.replace("T", " ");
      data = data.substr(0, data.length - 6);
      if (formato == '1') { // somente ano-mes-dia
        data = data.substr(0, 16);

      }
    }

    //13/12/2021 14:54:00
    if (data.substr(2, 1) == '/' || data.substr(2, 1) == '-') {
      let Dia = data.substr(0, 2);
      let Mes = data.substr(3, 2);
      let Ano = data.substr(6, 4);
      let Hora = data.substr(11, 5);
      data = Ano + '/' + Mes + '/' + Dia + ' ' + Hora
    }


    return data;
  }

  async checaDataInserirBancoSemHora(data: any, formato: string = '') {

    if (data.toString().indexOf('T') > -1) {
      data = data.replace("T", " ");
      data = data.substr(0, data.length - 6);
      if (formato == '1') { // somente ano-mes-dia
        data = data.substr(0, 10);

      }
    }

    //13/12/2021 14:54:00
    if (data.substr(2, 1) == '/' || data.substr(2, 1) == '-') {
      let Dia = data.substr(0, 2);
      let Mes = data.substr(3, 2);
      let Ano = data.substr(6, 4);
      let Hora = data.substr(11, 5);
      data = Ano + '-' + Mes + '-' + Dia
    }




    return data.substring(0, 10);
  }

  async checaDataInserirBancoComHora(data: any, formato: string = '') {

    if (data.toString().indexOf('T') > -1) {
      data = data.replace("T", " ");
      data = data.substr(0, data.length - 6);
      if (formato == '1') { // somente ano-mes-dia
        data = data.substr(0, 10);

      }
    }

    //13/12/2021 14:54:00
    if (data.substr(2, 1) == '/' || data.substr(2, 1) == '-') {
      let Dia = data.substr(0, 2);
      let Mes = data.substr(3, 2);
      let Ano = data.substr(6, 4);
      let Hora = data.substr(11, 5);
      data = Ano + '-' + Mes + '-' + Dia + ' ' + Hora
    } else {
      data = data.replace('/', '-');
      data = data.replace('/', '-');
      data = data.replace('/', '-');

    }




    return data;
  } ca

  async checaDataGeral(data: string, hora: number) {
    // api local ou api no servidor

    let api: any = this.obterUrlDaApi();
    if (api.indexOf('localhost') > -1) {
      //local
      let Dia = data.substring(0, 2);
      let Mes = data.substring(3, 5);
      let Ano = data.substring(6, 10);
      let Hora = data.substring(11, 16);
      data = Ano + '-' + Mes + '-' + Dia

      if (hora == 1) {
        data == data + ' ' + Hora
      }


    } else {
      // servidor
      // 4/28/1962 
      let dtServidor: any = new Date(data)
      let dia: any = dtServidor.getDate();
      let mes: any = dtServidor.getMonth() + 1
      let ano: any = dtServidor.getFullYear();
      let hora: any = dtServidor.getHours();          // 0-23
      let minuto: any = dtServidor.getMinutes();        // 0-59

      if (dia < 10) {
        dia = "0" + dia.toString();
      }

      if (mes < 10) {
        mes = "0" + mes.toString();;
      }

      if (hora < 10) {
        hora = "0" + hora.toString();;
      }

      if (minuto < 10) {
        minuto = "0" + minuto.toString();;
      }

      data = ano.toString() + "-" + mes.toString() + "-" + dia.toString()

      if (hora == 1) {
        data = data + " " + hora.toString() + ':' + minuto.toString();
      }
    }

    return data;


  }





  async checaDataSemHora(data: String) { // para mostrar no combo de data

    data = data.substr(0, 10);

    let Dia = data.substr(0, 2);
    let Mes = data.substr(3, 2);
    let Ano = data.substr(6, 4);
    let Hora = data.substr(11, 5);
    data = Ano + '-' + Mes + '-' + Dia

    return data;
  }

  async selecionaPessoa() {
    this.aPessoa.push({ Id: "1", Nome: "Pessoa Física" })
    this.aPessoa.push({ Id: "2", Nome: "Pessoa Jurídica" })
    this.aPessoa.push({ Id: "3", Nome: "Grupo Familiar" })
    this.aPessoa.push({ Id: "4", Nome: "Evento" })
    this.aPessoa.push({ Id: "5", Nome: "Turista" })
    return this.aPessoa;
  }

  async selecionaTipo() {
    this.aTipo.push({ Id: "1", Nome: "Faixa Etária" })
    this.aTipo.push({ Id: "2", Nome: "Faixa Numérica" })
    this.aTipo.push({ Id: "3", Nome: "Período" })
    return this.aTipo;

  }


  focusInput(input: any) {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(() => {
      (input as any).setFocus(true);

    }, 1000);
  }

  private eventSubject = new Subject<any>();

  publishEvent(key: any, value: any) {
    let data = { key: key, value: value };
    this.eventSubject.next(data);
  }

  getEvent(): Subject<any> {
    return this.eventSubject;
  }




  formatNumber(Valor: any) {

    // posibilidade de valor vazio

    if (Valor == 0 || Valor == '' || Valor == null || Valor == undefined) {
      Valor = '0.00'
    }

    // posibilidade de não ter decimais

    if (Valor.toString().indexOf(',') == -1 && Valor.toString().indexOf('.') == -1) {
      Valor = Valor + '.00'
    }

    // posibilidade do decimal ser menor que 2 digitos

    var s: string = '';
    if (Valor.toString().indexOf('.') > -1) {
      s = '.'
    } else if (Valor.toString().indexOf(',') > -1) {
      s = ','
    }

    if (s != '') {
      let v: any = Valor.toString().split(s);
      let a: string = v[0];
      let b: string = v[1];
      if (b.length < 2) {
        Valor = a + '.' + b + '0';
      } else if (b.length == 2) {
        Valor = a + '.' + b
      } else {
        var c: any = [];

        for (let i = 0; i < b.length; i++) {
          c.push(parseInt(b[i]))
        }

        var d: number = 0;
        for (let i = c.length - 1; i > 1; i--) {
          if (c[i] > 4 && c[i - 1] < 9) {
            c[i - 1] = c[i - 1] + 1
          }


        }
        c
        Valor = a + '.' + c[0].toString() + c[1].toString();
      }

    }




    return Valor


  }

  convertSecondsToTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
  
    const hoursStr = hours.toString().padStart(2, '0');
    const minutesStr = minutes.toString().padStart(2, '0');
    const secondsStr = secs.toString().padStart(2, '0');
  
    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  }

  formateNumber(valor: any, decimal: number, s: number = 0) {
    if (valor == '' || valor == undefined && valor == null) {
      return '';
    }
    let Inteirio: any = ''

    valor = valor.toString();
    let valornovo: any;

    if (valor.indexOf(',') > -1) {
      valornovo = valor.split(',');
      if (decimal > 0) {
        valornovo = valornovo[0] + '.' + valornovo[1].substr(0, decimal);
        //valornovo = parseFloat(valornovo);
        if (s > 0) {
          let sNumero: string = valornovo[1].substr(0, 1);
          let Numero: number = parseInt(sNumero)
          if (Numero >= 5) {
            let NumeroNovo: number = parseInt(Inteirio) + 1;
            valor = NumeroNovo;
          } else {
            let NumeroNovo: number = parseInt(Inteirio);
            valor = NumeroNovo;
          }
        }


      } else {
        valor = parseInt(valor);
      }
    } else if (valor.indexOf('.') > -1) {
      if (decimal > 0) {
        valornovo = valor.split('.');
        Inteirio = valornovo[0]
        valornovo = valornovo[0] + '.' + valornovo[1].substr(0, decimal);
        valor = parseFloat(valornovo);
        if (s > 0) {
          let sNumero: string = valornovo[1].substr(0, 1);
          let Numero: number = parseInt(sNumero)
          if (Numero >= 5) {
            let NumeroNovo: number = parseInt(Inteirio) + 1;
            valor = NumeroNovo;
          } else {
            let NumeroNovo: number = parseInt(Inteirio);
            valor = NumeroNovo;
          }
        }
      } else {
        valor = parseInt(valor);
      }
    }


    return valor;
  }

  async atualizarDadosDoSistema(dadosRemove: any, callback = null) {
    let loading = await this.loadingController.create({
      message: 'atualizando dados do sistema..',
    });
    loading.present();
    let dadosCripto = JSON.stringify(dadosRemove); //String para base64
    localStorage.setItem('dadosRemove', dadosCripto);
    if (callback != null) {
      loading.dismiss();
      callback();
    } else {
      loading.dismiss();
    }
    loading.dismiss();
  }

  obterDadosDoSistema() {
    let dados = localStorage.getItem('dadosRemove');
    if (dados == null) {
      return null;
    }


    let dadosRemove: SistemaModule = (JSON.parse(dados) as SistemaModule);
    return dadosRemove
  }

  async checaDados() {

    let dados = localStorage.getItem('dadosRemove');
    if (dados == null) {
      this.aUsuario.push({
        Id: 0,
        NomeUsuario: '',
        EmailUsuario: '',
        DiaUsuario: 0,
        MesUsuario: 0,
        AnoUsuario: 0,
        Logado: 0,
        Administrador: 0,
        Coordenador: 0,
        Imagem: '',
        Conselho: 0,
        UsuarioPesquisa: 0,
        PacientePesquisa: 0,
        EmpresaPesquisa: 0,
        ProdutoPesquisa: 0,
        ContratoPesquisa: 0,
        VeiculoPesquisa: 0,
        RemocaoPesquisa: 0
      });

      //

      this.aRemocao.push({
        Id: 0,
        Veiculo: 0,
        Condutor: 0,
        Estado: 0,
        Municipio: 0,
        Enfermeiro: 0,
        Medico: 0,
        Km: 0,
      });


      //
      this.dadosRemove.Usuario = this.aUsuario;
      this.dadosRemove.Remocao = this.aRemocao;

      this.atualizarDadosDoSistema(this.dadosRemove, null)
    }
  }


  async showToastCallBack(message: string, duration = 4000, callback) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      color: 'light',
      position: "top",
      mode: 'ios',
      buttons: [
        {
          side: 'end',
          icon: 'close',
          text: '',
          handler: () => {
          }
        }
      ]

    });

    toast.present();

    toast.onDidDismiss().then(() => {
      if (callback != null) {
        callback();
      }
    });
  }

  getDataBrowserBrasil(data: string) {
    let hoje = new Date(data);
    let dia: any = hoje.getDate();
    let mes: any = hoje.getMonth() + 1
    let ano: any = hoje.getFullYear();
    let hora: any = hoje.getHours();          // 0-23
    let minuto: any = hoje.getMinutes();        // 0-59

    if (dia < 10) {
      dia = "0" + dia.toString();
    }

    if (mes < 10) {
      mes = "0" + mes.toString();;
    }

    if (hora < 10) {
      hora = "0" + hora.toString();;
    }

    if (minuto < 10) {
      minuto = "0" + minuto.toString();;
    }

    data = dia.toString() + "/" + mes.toString() + "/" + ano.toString() //+ " " + hora.toString() + ':' + minuto.toString() + " h";

    return data
  }

  dataHoje() {
    let data: any = new Date();
    let dia: any = data.getDate();
    let mes: any = data.getMonth() + 1
    let ano: any = data.getFullYear();
    let hora: any = data.getHours();          // 0-23
    let minuto: any = data.getMinutes();        // 0-59

    if (dia < 10) {
      dia = "0" + dia.toString();
    }

    if (mes < 10) {
      mes = "0" + mes.toString();;
    }

    if (hora < 10) {
      hora = "0" + hora.toString();;
    }

    if (minuto < 10) {
      minuto = "0" + minuto.toString();;
    }
    data = ano.toString() + "-" + mes.toString() + "-" + dia.toString() + " " + hora.toString() + ':' + minuto.toString();
    return data
  }

  dataHojeSemHora() {
    let data: any = new Date();
    let dia: any = data.getDate();
    let mes: any = data.getMonth() + 1
    let ano: any = data.getFullYear();
    let hora: any = data.getHours();          // 0-23
    let minuto: any = data.getMinutes();        // 0-59

    if (dia < 10) {
      dia = "0" + dia.toString();
    }

    if (mes < 10) {
      mes = "0" + mes.toString();;
    }

    if (hora < 10) {
      hora = "0" + hora.toString();;
    }

    if (minuto < 10) {
      minuto = "0" + minuto.toString();;
    }

    data = ano.toString() + "-" + mes.toString() + "-" + dia.toString();

    return data
  }

  subtracaoData() {
    let d1: any = new Date('2021-10-05');
    let d2: any = new Date('2021-11-12');
    let diffInMs: number = d2 - d1;
    let diffInDays = diffInMs / (1000 * 60 * 60 * 24);
  }

 

  somaData(dtAtual: any, dias: number) {
    let dtInicial = new Date(dtAtual);
    let dtFinal = new Date();
    dtFinal.setDate(dtInicial.getDate() + dias); // Adiciona 365 dias
    let dia: any = dtFinal.getDate();
    let mes: any = dtFinal.getMonth() + 1
    let ano: any = dtFinal.getFullYear();
    let hora: any = dtFinal.getHours();          // 0-23
    let minuto: any = dtFinal.getMinutes();        // 0-59

    if (dia < 10) {
      dia = "0" + dia.toString();
    }

    if (mes < 10) {
      mes = "0" + mes.toString();;
    }

    if (hora < 10) {
      hora = "0" + hora.toString();;
    }

    if (minuto < 10) {
      minuto = "0" + minuto.toString();;
    }

    let data = ano.toString() + "-" + mes.toString() + "-" + dia.toString();

    return data
  }

  obterHeaderApi() {
    let token = localStorage.getItem('token');
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
        .set('Content-Type', `application/json`)
    }
    return header;
  }

  EnviarEmail(para: string, paraNome: string, assunto: string, mensagem: string,
    imagem: string, stringBase64Pdf: any = '', link: string) { // usuario contem todos os campos para o cadastro
    if (link == null || link == '' || link == undefined) {
      link = 'https:/removeuti.com.br'
    }
    let parametro =
    {
      para: para,
      assunto: assunto,
      mensagem: mensagem,
      paraNome: paraNome,
      imagem: imagem,
      anexoPdf: stringBase64Pdf,
      link: link
    };
    let url = this.obterUrlDaApi() + 'Util/_EnviarEmail';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(url, parametro, { headers: headers }).toPromise();
  }


}
