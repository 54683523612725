import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {  ModalController, LoadingController, AlertController, IonThumbnail } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from '../../services/util.service';
import { ApiService } from '../../services/api.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-remocao-trecho',
  templateUrl: './remocao-trecho.component.html',
  styleUrls: ['./remocao-trecho.component.scss'],
})
export class RemocaoTrechoComponent implements OnInit {
  @ViewChild('ancoraLocalatual', null) ancoraLocalatual: ElementRef;
  @ViewChild('ancoraMunicipiopesquisa', null) ancoraMunicipiopesquisa: ElementRef;
  @ViewChild('ancoraKm', null) ancoraKm: ElementRef;
  @ViewChild('ancoraIntercorrencia', null) ancoraIntercorrencia: ElementRef;

  Botao: string = "ADICIONAR"
  remocao_trechoForm: FormGroup;
  NomePaciente: string = "";
  IdRemocao: any;
  aEstado: any = [];
  aMunicipio: any = [];
  aVeiculo: any = [];
  aUsuario: any = [];
  aMedico: any = [];
  aCondutor: any = [];
  aEnfermeiro: any = [];
  aMunicipioPesquisa: any = [];
  aStatusatual: any = [];
  lRet: any;
  aRemocaotrecho: any = [];
  Id: any = '';
  aRemocaoTrechoTodos: any = [];
  dadosRemove: any;
  IdTrecho: number = 0;
  Km: number = 0
  latitude :any;
  longitude : any;
  constructor(
    private modalController: ModalController,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private loadingController: LoadingController,
    private alertController: AlertController
  ) {

    this.remocao_trechoForm = formBuilder.group({
      Veiculo: ['', ''],
      Id: ['', ''],
      Condutor: ['', ''],
      Enfermeiro: ['', ''],
      Medico: ['', ''],
      Data: ['', ''],
      Estado: ['', ''],
      Municipio: ['', ''],
      Localatual: ['', ''],
      Km: ['', ''],
      Statusatual: ['', ''],
      Intercorrencia: ['', ''],
      Latitude: ['', ''],
      Longitude: ['', ''],
      Municipiopesquisa: ['', ''],

    })
    this.NomePaciente = this.activatedRoute.snapshot.paramMap.get('NomePaciente');
    this.IdRemocao = parseInt(this.activatedRoute.snapshot.paramMap.get('IdRemocao'));
    this.dadosRemove = this.utilService.obterDadosDoSistema();
    this.IdTrecho = parseInt(this.activatedRoute.snapshot.paramMap.get('IdTrecho'));


  }

  ngOnInit() {

    //Pega longitude e latitude
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      },
        function (error) {
          console.error("Erro ao obter localização: ", error);
        }
      );
    } else {
      console.error("Geolocalização não é suportada pelo seu navegador.");
    }

    this.apiService.selecionarVeiculoNome('0')
      .then((response: any) => {
        this.aVeiculo = response;
      });



    this.utilService.selecionarEstado()
      .then((response: any) => {
        this.aEstado = response;
      });

    this.utilService.selecionarStatusRemocao()
      .then((response: any) => {
        this.aStatusatual = response;
      });

    this.apiService.selecionarUsuarioNome('0')
      .then((response: any) => {
        this.aUsuario = response;
        this.aMedico = this.aUsuario.filter((usuario) => (usuario.Conselho == 6));
        this.aCondutor = this.aUsuario.filter((usuario) => (usuario.Conselho == 18));
        this.aEnfermeiro = this.aUsuario.filter((usuario) => (usuario.Conselho == 2));


      });


  }

  ionViewDidEnter() {


    setTimeout(() => {
      if (this.IdTrecho > 0) {
        this.SelecionarDadosId(this.IdTrecho);
      } else {
        this.limpaDados();
      }

    }, 1000)



  }

  closeModal() {
    this.modalController.dismiss();
  }



  async selecionarLocalAtual(Id: number) {
    let cTexto: string = '';
    if (Id == 1) {
      cTexto = 'SAÍDA DA BASE EM ILHÉUS';
      this.remocao_trechoForm.controls["Localatual"].setValue(cTexto);

    }

  }

  async consultarMunicipio() {

    let Estado = this.remocao_trechoForm.value.Estado;
    let Municipiopesquisa = this.remocao_trechoForm.value.Municipiopesquisa;

    if (Estado == '' || Estado == null || Estado == undefined) {
      {
        this.utilService.showAlertCallback("Escolha o ESTADO", () => {
          this.utilService.focusInput(this.ancoraMunicipiopesquisa);

        });
        return;
      }
    }

    if (Municipiopesquisa == '' || Municipiopesquisa == null || Municipiopesquisa == undefined) {
      {
        this.utilService.showAlertCallback("Escolha o nome do MUNICÍPIO a ser pesquisado", () => {
          this.utilService.focusInput(this.ancoraMunicipiopesquisa);

        });
        return; return;
      }
    }
    this.aMunicipioPesquisa = this.aMunicipio.filter((municipio) => (municipio.nome.toUpperCase().indexOf(Municipiopesquisa.toUpperCase()) > -1));

  }

  async obterMunicipio(estado: string) {
    this.utilService.obterMunicipio(estado).then((response: any) => {
      this.aMunicipio = response;
      this.aMunicipioPesquisa = response;
      this.utilService.focusInput(this.ancoraMunicipiopesquisa);
    })

  }



  async testarDado() {
    let loading = await this.loadingController.create({ message: "Verificando..." });
    loading.present();

    this.lRet = this.utilService.valiidarCampoGenerico("Remocao", this.IdRemocao, () => {
      this.utilService.focusInput(this.ancoraKm);
    });
    if (this.lRet == false) {
      loading.dismiss();
      return;
    }

    this.lRet = this.utilService.valiidarCampoGenerico("Nome", this.NomePaciente, () => {
      this.utilService.focusInput(this.ancoraKm);
    });
    if (this.lRet == false) {
      loading.dismiss();
      return;
    }

    this.lRet = this.utilService.valiidarCampoGenerico("Data", this.remocao_trechoForm.value.Data, () => {
      this.utilService.focusInput(this.ancoraKm);
    });
    if (this.lRet == false) {
      loading.dismiss();
      return;
    }



    this.lRet = this.utilService.valiidarCampoGenerico("Veiculo", this.remocao_trechoForm.value.Veiculo, () => {
      this.utilService.focusInput(this.ancoraKm);
    });

    if (this.lRet == false) {
      loading.dismiss();
      return;
    }

    this.lRet = this.utilService.valiidarCampoGenerico("Km", this.remocao_trechoForm.value.Km, () => {
      this.utilService.focusInput(this.ancoraKm);
    });

    if (this.lRet == false) {
      loading.dismiss();
      return;
    }

    this.lRet = this.utilService.valiidarCampoGenerico("Condutor", this.remocao_trechoForm.value.Condutor, () => {
      this.utilService.focusInput(this.ancoraKm);
    });

    if (this.lRet == false) {
      loading.dismiss();
      return;
    }

    this.lRet = this.utilService.valiidarCampoGenerico("Statusatual", this.remocao_trechoForm.value.Status, () => {
      this.utilService.focusInput(this.ancoraLocalatual);
    });

    if (this.lRet == false) {
      loading.dismiss();
      return;
    }

    this.lRet = this.utilService.valiidarCampoGenerico("Estado", this.remocao_trechoForm.value.Estado, () => {
      this.utilService.focusInput(this.ancoraLocalatual);
    });

    if (this.lRet == false) {
      loading.dismiss();
      return;
    }

    this.lRet = this.utilService.valiidarCampoGenerico("Municipio", this.remocao_trechoForm.value.Municipio, () => {
      this.utilService.focusInput(this.ancoraLocalatual);
    });

    if (this.lRet == false) {
      loading.dismiss();
      return;
    }


    this.lRet = this.utilService.valiidarCampoGenerico("Localatual", this.remocao_trechoForm.value.Localatual, () => {
      this.utilService.focusInput(this.ancoraLocalatual);
    });

    if (this.lRet == false) {
      loading.dismiss();
      return;
    }

    this.lRet = this.utilService.valiidarCampoGenerico("Enfermeiro", this.remocao_trechoForm.value.Enfermeiro, () => {
      this.utilService.focusInput(this.ancoraLocalatual);
    });

    if (this.lRet == false) {
      loading.dismiss();
      return;
    }

    this.lRet = this.utilService.valiidarCampoGenerico("Medico", this.remocao_trechoForm.value.Medico, () => {
      this.utilService.focusInput(this.ancoraLocalatual);
    });

    if (this.lRet == false) {
      loading.dismiss();
      return;
    }


    let Data: any = this.remocao_trechoForm.value.Data;




    this.utilService.checaDataInserirBancoComHora(Data).then((data) => {
      Data = data;
    });
    this.aRemocaotrecho = []
    loading.dismiss();


    setTimeout(() => {
      
      this.aRemocaotrecho = {
        Id: this.remocao_trechoForm.value.Id,
        Nome: this.NomePaciente,
        Data: Data,
        Remocao: this.IdRemocao,
        Veiculo: this.remocao_trechoForm.value.Veiculo,
        Km: this.remocao_trechoForm.value.Km,
        Statusatual: this.remocao_trechoForm.value.Statusatual,
        Condutor: this.remocao_trechoForm.value.Condutor,
        Estado: this.remocao_trechoForm.value.Estado,
        Municipio: this.remocao_trechoForm.value.Municipio,
        Localatual: this.remocao_trechoForm.value.Localatual,
        Medico: this.remocao_trechoForm.value.Medico,
        Enfermeiro: this.remocao_trechoForm.value.Enfermeiro,
        Intercorrencia: this.remocao_trechoForm.value.Intercorrencia,
        Latitude : this.latitude.toString(),
        Longitude : this.longitude.toString()
      }
      this.presentAlertConfirm();
    }, 1000); //1 seg



  }

  async presentAlertConfirm() {
    let sheader = 'ATUALIZAÇÃO';
    if (this.remocao_trechoForm.value.Id == '' || this.remocao_trechoForm.value.Id == null || this.remocao_trechoForm.value.Id == undefined) {
      sheader = 'ADIÇÃO';
    }
    const alert = await this.alertController.create({
      header: sheader,
      message: '<strong>CONFIRMA a gravação desses dados?</strong>',
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: 'Confirmo',
          handler: () => {
            this.gravarDados();

          }
        }
      ]
    });

    await alert.present();
  }

  async gravarDados() {
    if (this.remocao_trechoForm.value.Id == '' || this.remocao_trechoForm.value.Id == null || this.remocao_trechoForm.value.Id == undefined) {
      this.AdicionarDados();
    } else {
      this.AtualizarDados();
    }

  }

  async AdicionarDados() {
    this.apiService.adicionarRemocaotrecho(this.aRemocaotrecho)
      .then((response: any) => {
        if (response[0].Retorno == 1) {
          this.utilService.showAlertCallback('Dados incluídos com sucesso!', null)
          this.Id = response[0].Id;
          this.remocao_trechoForm.controls["Id"].setValue(response[0].Id);
          this.SelecionarDadosRemocao();
          this.Botao = "ATUALIZAR"
          this.atualizarDadosSistema();
        } else {
          this.utilService.showAlertCallback('Esse remocao já está cadastrado!', null)

        }


      })
      .catch((response) => {
        this.utilService.showAlertCallback('Dados não poderam ser incluídos.', null);
      });

  }

  async atualizarDadosSistema() {
    let aRemocao: any = []
    aRemocao.push({
      Id: this.aRemocaotrecho.Remocao,
      Veiculo: this.aRemocaotrecho.Veiculo,
      Condutor: this.aRemocaotrecho.Condutor,
      Estado: this.aRemocaotrecho.Estado,
      Municipio: this.aRemocaotrecho.Municipio,
      Enfermeiro: this.aRemocaotrecho.Enfermeiro,
      Medico: this.aRemocaotrecho.Medico,
      Km: this.aRemocaotrecho.Km,
      Latitude : this.latitude,
      Longitude : this.longitude
    });
    this.dadosRemove.Remocao = aRemocao;
    this.utilService.atualizarDadosDoSistema(this.dadosRemove)


  }
  async AtualizarDados() {
    this.apiService.atualizarRemocaotrecho(this.aRemocaotrecho)
      .then((response: any) => {
        if (response[0].Retorno == 1) {
          this.utilService.showAlertCallback('Dados atualizados com sucesso!', null);
          this.SelecionarDadosRemocao();
          this.atualizarDadosSistema();

        }

      })
      .catch((response) => {
        this.utilService.showAlertCallback('Dados não poderam ser atualizados.', null);
      });

  }

  async SelecionarDadosRemocao() {
    this.apiService.selecionarRemocaotrechoRemocao(this.IdRemocao)
      .then((response: any) => {

        this.aRemocaoTrechoTodos = response
        let nStatus: number = 0;
        for (let i = 0; i < this.aRemocaoTrechoTodos.length; i++) {
          nStatus = this.aRemocaoTrechoTodos[i].Statusatual
          for (let z = 0; z < this.aStatusatual.length; z++) {
            if (this.aStatusatual[z].Id == nStatus) {
              this.aRemocaoTrechoTodos[i].Descricaostatus = this.aStatusatual[z].Nome
              break;
            }
          }
        }

      })
      .catch((response) => {
        this.utilService.showAlertCallback('Dados não encontrados.', null);
      });

  }

  async SelecionarDadosId(id: number) {
    this.apiService.selecionarRemocaotrechoId(id)
      .then((response: any) => {
        this.getDados(response);

      })
      .catch((response) => {
        this.utilService.showAlertCallback('Dados não encontrados.', null);
      });

  }



  async limpaDados() {
    let Veiculo: number = 0;
    let Condutor: number = 0;
    let Estado: number = 0;
    let Municipio: number = 0;
    let Medico: number = 0;
    let Enfermeiro: number = 0;
    let Km: number = 0;


    if (this.dadosRemove != null && this.dadosRemove.Remocao[0].Id > 0
      && this.dadosRemove.Remocao[0].Id == this.IdRemocao) {
      Veiculo = this.dadosRemove.Remocao[0].Veiculo;
      Condutor = this.dadosRemove.Remocao[0].Condutor;
      Estado = this.dadosRemove.Remocao[0].Estado;
      Municipio = this.dadosRemove.Remocao[0].Municipio;
      Medico = this.dadosRemove.Remocao[0].Medico;
      Enfermeiro = this.dadosRemove.Remocao[0].Enfermeiro;
      this.Km = this.dadosRemove.Remocao[0].Km;
    }

    let dtHoje: any = this.utilService.dataHoje();
    this.remocao_trechoForm.controls["Id"].setValue('');
    this.remocao_trechoForm.controls["Data"].setValue(dtHoje);
    this.remocao_trechoForm.controls["Veiculo"].setValue(Veiculo);
    this.remocao_trechoForm.controls["Km"].setValue('');
    this.remocao_trechoForm.controls["Condutor"].setValue(Condutor);
    this.remocao_trechoForm.controls["Estado"].setValue(Estado);
    this.remocao_trechoForm.controls["Localatual"].setValue('');
    this.remocao_trechoForm.controls["Statusatual"].setValue('');
    this.remocao_trechoForm.controls["Medico"].setValue(Medico);
    this.remocao_trechoForm.controls["Enfermeiro"].setValue(Enfermeiro);
    this.remocao_trechoForm.controls["Intercorrencia"].setValue('');

    setTimeout(() => {
      this.remocao_trechoForm.controls["Municipio"].setValue(Municipio);

    }, 1000)
    this.Id = '';
    this.Botao = "ADICIONAR";
    this.utilService.focusInput(this.ancoraKm);
    this.SelecionarDadosRemocao();

  }

  async getDados(response: any) {
    this.utilService.checaData(response[0].Data).then((Data) => {
      this.remocao_trechoForm.controls["Data"].setValue(Data);
    });
    this.remocao_trechoForm.controls["Estado"].setValue(response[0].Estado);

    this.remocao_trechoForm.controls["Id"].setValue(response[0].Id);
    this.remocao_trechoForm.controls["Statusatual"].setValue(response[0].Statusatual);
    this.remocao_trechoForm.controls["Veiculo"].setValue(response[0].Veiculo);
    this.remocao_trechoForm.controls["Condutor"].setValue(response[0].Condutor);
    this.remocao_trechoForm.controls["Km"].setValue(response[0].Km);
    this.remocao_trechoForm.controls["Enfermeiro"].setValue(response[0].Enfermeiro);
    this.remocao_trechoForm.controls["Medico"].setValue(response[0].Medico);
    this.remocao_trechoForm.controls["Intercorrencia"].setValue(response[0].Intercorrencia);
    this.remocao_trechoForm.controls["Localatual"].setValue(response[0].Localatual);

    setTimeout(() => {
      this.remocao_trechoForm.controls["Municipio"].setValue(response[0].Municipio);

    }, 1000)

    this.Botao = "ATUALIZAR";

  }


}
