import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthorizeGuard } from './guards/authorize-guard.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'contato',
    loadChildren: () => import('./contato/contato.module').then( m => m.ContatoPageModule)
  },
  {
    path: 'quemsomos',
    loadChildren: () => import('./quemsomos/quemsomos.module').then( m => m.QuemsomosPageModule)
  },
  {
    path: 'privacidade',
    loadChildren: () => import('./privacidade/privacidade.module').then( m => m.PrivacidadePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'administrativo',
    
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/administrativo/administrativo.module').then( m => m.AdministrativoPageModule)
  },
  {
    path: 'usuario',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/usuario/usuario.module').then( m => m.UsuarioPageModule)
  },
  {
    path: 'empresa',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/empresa/empresa.module').then( m => m.EmpresaPageModule)
  },
  
  {
    path: 'paciente',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/paciente/paciente.module').then( m => m.PacientePageModule)
  },
  {
    path: 'veiculo',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/veiculo/veiculo.module').then( m => m.VeiculoPageModule)
  },
  {
    path: 'movel',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/movel/movel.module').then( m => m.MovelPageModule)
  },
  {
    path: 'material',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/material/material.module').then( m => m.MaterialPageModule)
  },
  {
    path: 'medicamento',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/medicamento/medicamento.module').then( m => m.MedicamentoPageModule)
  },
  {
    path: 'estoque',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/estoque/estoque.module').then( m => m.EstoquePageModule)
  },
  {
    path: 'estoqueentrada',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/estoqueentrada/estoqueentrada.module').then( m => m.EstoqueentradaPageModule)
  },
  {
    path: 'estoquesaida',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/estoquesaida/estoquesaida.module').then( m => m.EstoquesaidaPageModule)
  },
  {
    path: 'orcamento',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/orcamento/orcamento.module').then( m => m.OrcamentoPageModule)
  },
 
  {
    path: 'checklistsaida',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/checklistsaida/checklistsaida.module').then( m => m.ChecklistsaidaPageModule)
  },
  
  {
    path: 'equipamento',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/equipamento/equipamento.module').then( m => m.EquipamentoPageModule)
  },
  {
    path: 'checklistretorno',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/checklistretorno/checklistretorno.module').then( m => m.ChecklistretornoPageModule)
  },
  {
    path: 'areamedica',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/areamedica/areamedica.module').then( m => m.AreamedicaPageModule)
  },
  {
    path: 'receitamedica',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/receitamedica/receitamedica.module').then( m => m.ReceitamedicaPageModule)
  },
 
  {
    path: 'usuario-pesquisa',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/usuario-pesquisa/usuario-pesquisa.module').then( m => m.UsuarioPesquisaPageModule)
  },
  {
    path: 'paciente-pesquisa',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/paciente-pesquisa/paciente-pesquisa.module').then( m => m.PacientePesquisaPageModule)
  },
  {
    path: 'empresa-pesquisa',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/empresa-pesquisa/empresa-pesquisa.module').then( m => m.EmpresaPesquisaPageModule)
  },
  {
    path: 'produto',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/produto/produto.module').then( m => m.ProdutoPageModule)
  },
  {
    path: 'produto-pesquisa',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/produto-pesquisa/produto-pesquisa.module').then( m => m.ProdutoPesquisaPageModule)
  },
  {
    path: 'contrato-pesquisa',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/contrato-pesquisa/contrato-pesquisa.module').then( m => m.ContratoPesquisaPageModule)
  },
  {
    path: 'contrato',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/contrato/contrato.module').then( m => m.ContratoPageModule)
  },
  {
    path: 'produto-simulacao',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/produto-simulacao/produto-simulacao.module').then( m => m.ProdutoSimulacaoPageModule)
  },
  {
    path: 'veiculo-pesquisa',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/veiculo-pesquisa/veiculo-pesquisa.module').then( m => m.VeiculoPesquisaPageModule)
  },
 
 
  {
    path: 'remocao',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/remocao/remocao.module').then( m => m.RemocaoPageModule)
  },

  {
    path: 'remocao-pesquisa',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/remocao-pesquisa/remocao-pesquisa.module').then( m => m.RemocaoPesquisaPageModule)
  },
  {
    path: 'remocao-relatorio',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/remocao-relatorio/remocao-relatorio.module').then( m => m.RemocaoRelatorioPageModule)
  },
  {
    path: 'mapa',
    canActivate : [AuthorizeGuard],
    loadChildren: () => import('./pages/mapa/mapa.module').then( m => m.MapaPageModule)
  },
  {
    path: 'telemedicina',
    loadChildren: () => import('./pages/telemedicina/telemedicina.module').then( m => m.TelemedicinaPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
